<script setup lang="ts">

import useVuelidate from "@vuelidate/core";
import {email, required} from '@vuelidate/validators'

const initialState = {
  email: '',
  password: '',
};
const formState = reactive({
  ...initialState
});
const formRules = {
  email: { required, email },
  password: { required },
}
const v$ = useVuelidate(formRules, formState);
const formError = ref('');

const passwordVisible = ref(false);

const router = useRouter();
const supabase = useSupabaseClient();
const handleLogin = async (event: Event) => {
  formError.value = '';

  const result = await v$.value.$validate();
  if (!result) {
    return
  }

  const {data, error} = await supabase.auth.signInWithPassword({
    email: formState.email,
    password: formState.password
  });

  if (error) {
    formError.value = error.message;
    return;
  }

  if (data) {
    const {setCookies} = useAuthCookies();
    setCookies(data.session?.access_token, data.session?.refresh_token);

    router.push('/dashboard');
  }
}
</script>

<template>
  <div>
<!--    <v-img-->
<!--      class="mx-auto my-6"-->
<!--      max-width="228"-->
<!--      src="https://cdn.vuetifyjs.com/docs/images/logos/vuetify-logo-v3-slim-text-light.svg"-->
<!--    ></v-img>-->
    <div class="mx-auto my-6 d-flex justify-center" style="max-width: 228px">
      <span class="text-xl-h3 font-weight-bold">eCRF</span>
    </div>

    <v-card
      class="mx-auto pa-12 pb-8"
      elevation="8"
      max-width="448"
      rounded="lg"
    >
      <div class="text-subtitle-1 text-medium-emphasis">E-Mail</div>

      <v-form @submit.prevent="handleLogin">
        <v-text-field
          v-model="formState.email"
          density="compact"
          placeholder="max@mustermann.at"
          prepend-inner-icon="mdi-email-outline"
          variant="outlined"
          :error-messages="v$.email.$errors.map(e => e.$message)"
          required
          @blur="v$.email.$touch"
          @input="v$.email.$touch"
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          Passwort

          <a
            class="text-caption text-decoration-none text-blue"
            href="/password-reset"
            rel="noopener noreferrer"
            target="_blank"
          >
            Passwort vergessen?</a>
        </div>

        <v-text-field
          v-model="formState.password"
          :append-inner-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="passwordVisible ? 'text' : 'password'"
          density="compact"
          placeholder="Passwort"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          :error-messages="v$.password.$errors.map(e => e.$message)"
          required
          @blur="v$.password.$touch"
          @input="v$.password.$touch"
          @click:append-inner="passwordVisible = !passwordVisible"
        ></v-text-field>

        <v-card
          v-if="formError.length >= 1"
          class="mb-4"
          color="red-lighten-4"
        >
          <v-card-text class=" text-caption text-red-darken-4">
           {{formError}}
          </v-card-text>
        </v-card>

        <v-btn
          class="mb-8"
          color="primary"
          size="large"
          type="submit"
          block
        >
          Anmelden
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<style scoped>

</style>
