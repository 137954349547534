/*
 * File: useAuthCookie.ts
 * Description: Reusable helper to access auth cookies.
 */

export const useAuthCookies = () => {
  const cookieName = useRuntimeConfig().public.supabase.cookieName;

  return {
    getCookies() {
      return {
        accessToken: useCookie(`${cookieName}-access-token`).value,
        refreshToken: useCookie(`${cookieName}-refresh-token`).value,
      }
    },
    setCookies(accessToken: string, refreshToken: string) {
      useCookie(`${cookieName}-access-token`).value = accessToken
      useCookie(`${cookieName}-refresh-token`).value = refreshToken
    },
    clearCookies() {
      useCookie(`${cookieName}-access-token`).value = null
      useCookie(`${cookieName}-refresh-token`).value = null
    }
  };
}
